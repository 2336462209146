<template>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('fields.orcamentos')}} / {{MyObject.clientenome}}</span>
      </v-card-title>
      <v-card-text v-if="Items.length >0">
        <LinhaDialog
          :MyObject="MyObject"
          :Title="`${MyObject.clientenome} / ${Obj.strcabid}`"
          :Items="LINHAS"
        />
        <v-list>
          <v-list-item :color="$primary">
            <v-list-item v-for="(item, idx) in Items" :key="idx">
              <v-list-item-content>
                <v-list-item-title>{{item.strcabid}} - {{item.strdetailsdoc}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="getLinhas(item)" icon>
                  <v-icon color="success">mdi-format-list-bulleted-square</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-text v-else>
        <p>{{$t('messages.semOrcamentos')}}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="$primary" text @click="close">{{$t('actions.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    MyObject: Object,
    Items: {
      type: Array,
      default: []
    }
  },
  components: {
    LinhaDialog: () => import("@/components/dialogs/LinhaDialog")
  },
  computed: {
    getSession() {
      return this.$store.getters["session/getState"]
    }
  },
  data: () => ({
    dialog: false,
    LINHAS: [],
    Obj: {}
  }),
  watch: {},
  mounted() {},
  created() {
    this.$eventBus.$on("OrcamentoDialog", state => {
      this.dialog = state
    })
  },
  methods: {
    getLinhas(item) {
      if (!item.strcabid) return

      this.$eventBus.$emit("loader", true)
      this.$store
        .dispatch("linha/get", {
          url: this.$serviceUrl,
          pwd: this.$servicePwd,
          orcamento: item.strcabid
        })
        .then(res => {
          this.Obj = { ...item }
          this.LINHAS = res
          this.$eventBus.$emit("LinhaDialog", true)
          this.$eventBus.$emit("loader", false)
        })
    },
    close() {
      this.dialog = false

      setTimeout(() => {
        this.$eventBus.$emit("ClearDialog")
      }, 110)
    }
  }
}
</script>
<style scoped>
</style>
